<template>
  <div>
    <div class="search-bar">
      <a-form-model layout="inline" ref="searchRef">
        <a-form-model-item prop="search_date" label="选择时间">
          <a-range-picker
            style="width:300px"
            v-model="search_date"
            valueFormat="YYYY-MM-DD"
          />
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" html-type="submit" @click="handlerSearch">搜索</a-button>
        </a-form-model-item>
      </a-form-model>
    </div>

    <div class="mt-2">
      <a-tabs type="card" class="w-full" @change="changeTabs" :activeKey="activeTab">
        <a-tab-pane :key="0" tab="本店交付"></a-tab-pane>
        <a-tab-pane :key="1" tab="本店下单"></a-tab-pane>
      </a-tabs>
    </div>

	<template v-if="activeTab==0">
		<div class="text-sm mt-4 mb-2">订单总览</div>
		<base-table style="max-width:900px;"
			:columnsData="columns_carry"
			:rowKey="rowKey"
			:tableData="tableData_carry"
		></base-table>

		<div class="text-sm mt-4 mb-2">配送员数据（单位:单）</div>
		<base-table style="max-width:900px;"
			:columnsData="columns_courier"
			:tableData="tableData_courier"
		></base-table>
	</template>

	<template v-if="activeTab==1">
		<div class="text-sm mt-4 mb-2">下单总览</div>
		<base-table style="max-width:900px;"
			:columnsData="columns_place"
			:rowKey="rowKey"
			:tableData="tableData_place"
		></base-table>
		<div class="text-sm mt-4 mb-2">支付方式分布（单位:元）</div>
		<base-table style="max-width:900px;"
			:columnsData="columns_pay_simple"
			:rowKey="rowKey"
			:tableData="tableData_placepay"
		></base-table>
	</template>

  </div>
</template>

<script>
import pageData from "./columns"
import { financeOutletsCarry, financeOutletsPlace, financeOutletsCourier } from "@/api/finance/finance.js"
import { formatEnMoney } from "@/utils/index.js"

export default {
  data() {
    return {
			activeTab: 0,
			search_date: [],
			
			...pageData,
			tableData_carry: [],
			columns_carry: [
				{
					title: "订单数-自提",
					dataIndex: "pick_up_count",
					align: "center",
				},
				{
					title: "订单数-配送",
					dataIndex: "delivery_count",
					align: "center",
				},
				{
					title: "订单应收(元)",
					dataIndex: "total_receivable_amount",
					align: "center",
					slots: {
						customRender: "total_receivable_amount",
					},
					slotsType: "format",
					slotsFunc: (val) => {return formatEnMoney(val)},
				},
				{
					title: "应退款(元)",
					dataIndex: "bear_refund_amount",
					align: "center",
					slots: {
						customRender: "bear_refund_amount",
					},
					slotsType: "format",
					slotsFunc: (val) => {return formatEnMoney(val)},
				},
				{
					title: "货到付款(元)",
					dataIndex: "cash_delivery_income_amount",
					align: "center",
					slots: {
						customRender: "cash_delivery_income_amount",
					},
					slotsType: "format",
					slotsFunc: (val) => {return formatEnMoney(val)},
				},
			],

			tableData_place: [],
			columns_place: [
				{
					title: "下单数",
					dataIndex: "place_order_count",
					align: "center",
				},
				{
					title: "完成订单数",
					dataIndex: "finish_count",
					align: "center",
				},
				{
					title: "订单金额(元)",
					dataIndex: "order_amount",
					align: "center",
					slots: {
						customRender: "order_amount",
					},
					slotsType: "format",
					slotsFunc: (val) => {return formatEnMoney(val)},
				},
				{
					title: "订单退款(元)",
					dataIndex: "refund_amount",
					align: "center",
					slots: {
						customRender: "refund_amount",
					},
					slotsType: "format",
					slotsFunc: (val) => {return formatEnMoney(val)},
				},
			],

			tableData_placepay: [],

			tableData_courier: [],
			columns_courier: [],
    }
  },
  mounted() {
		this.search_date = [
			// this.getThisMonthFirstDay(),
			this.getToday(),
			this.getToday(),
		]
		this.handlerSearch()
  },
  methods: {
    changeTabs(){
        this.activeTab = this.activeTab==1 ? 0 : 1
    },
    async initDataCarryOrder() {
		const params = {
			time_start: this.search_date[0] + ' 00:00:00',
			time_end:   this.search_date[1] + ' 23:59:59'
		}
		const { data, code } = await financeOutletsCarry(params)
		if (code === 0) {
			this.tableData_carry = [data]
		}
    },

    async initDataPlaceOrder() {
		const params = {
			time_start: this.search_date[0] + ' 00:00:00',
			time_end:   this.search_date[1] + ' 23:59:59'
		}
		const { data, code } = await financeOutletsPlace(params)
		if (code === 0) {
			this.tableData_place = [data]
			this.tableData_placepay = [data.order_amount_data]
		}
    },

    async initDataCourier() {
		const params = {
			time_start: this.search_date[0] + ' 00:00:00',
			time_end:   this.search_date[1] + ' 23:59:59'
		}
		const { data, code } = await financeOutletsCourier(params)
		if (code === 0) {
			let columns_courier = []
			let data_courier = {}
			data.list.map(item=>{
				let dataIndex = "id_"+columns_courier.length
				columns_courier.push({
					title: item.name,
					dataIndex: dataIndex,
					align: "center",
				})
				data_courier[dataIndex] = item.order_count
			})

			this.columns_courier = columns_courier
			this.tableData_courier = [data_courier]
		}
    },

    // 搜索
    handlerSearch() {
		if (this.search_date.length < 2) {
			this.$message.warning("请选择日期")
			return
		}
		this.initDataCarryOrder()
		this.initDataCourier()

		this.initDataPlaceOrder()  
    },

    // -----------
    getLastMonthFirstDay(){
			const today = new Date()
			let month = today.getMonth()
			let year = today.getFullYear()
			// 1月：上一个月 是去年12月
			if(month == 0){
				month = 11
				year = year-1
			}else{
				month = month-1
			}
			return this.formatDate(new Date(year, month, 1))
    },
    getLastMonthLastDay(){
			let date = new Date()
			date.setTime(date.getTime() - date.getDate()*24*3600*1000)

			return this.formatDate(date)
    },
    getThisMonthFirstDay(){
			const today = new Date()
			let month = today.getMonth()
			let year = today.getFullYear()
			return this.formatDate(new Date(year, month, 1))
    },
    getToday(){
			let date = new Date()
			return this.formatDate(date)
    },
    
    formatDate(date){
		let year = date.getFullYear()
		let month = date.getMonth()
		let day = date.getDate()
		month = month+1
		if(month < 10){
			month = "0"+month
		}
		if(day < 10){
			day = "0"+day
		}
		return year+"-"+month+"-"+day
    },

  },
}
</script>

<style>
</style>